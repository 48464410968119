<template>
<div class="scroll ">
  <router-view></router-view>
</div>
</template>

<script>


export default {
  name: "JobPosting",
  data(){
    return{
     currentStep : 3
    }
  },
  methods:{
  handlePrevStep(){
    if (this.currentStep > 1)
      return this.currentStep -=1
  },
  handleNextStep(){
    if (this.currentStep < 9)
      return this.currentStep +=1
  }
  }
}
</script>

<style  lang="scss">

.select-input{
  //background: linear-gradient(0deg, #FFFFFF, #FFFFFF);
  border: none !important;
  box-sizing: border-box;
  border-radius: 6px;
  font-family: DM Sans;
  display: flex;
  align-items: center;
  color: #001343;
  cursor: pointer;
}
.label{
  font-family: DM Sans !important;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #1E323F;
}
.select{
  padding: 16px 32px;
  width: max-content;
  height: 56px;
  border: 1px solid #D0DCE4;
  box-sizing: border-box;
  border-radius: 8px;
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 24px;
  color: #475661;
}
.select:hover{
  padding: 16px 32px;
  width: max-content;
  height: 56px;
  background: #F7FCFF;
  border: 1px solid #008EEF;
  box-sizing: border-box;
  border-radius: 8px;
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #008EEF;
  cursor: pointer;

}

.select-web{
  padding: 16px 32px;
  width: max-content;
  height: 56px;
  box-sizing: border-box;
  border-radius: 8px;
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #475661;
}
.select-web:hover{
  padding: 16px 32px;
  width: max-content;
  height: 56px;
  background: #F7FCFF;
  border: 1px solid #008EEF;
  box-sizing: border-box;
  border-radius: 8px;
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #008EEF;
  cursor: pointer;

}


.selected{
  padding: 16px 32px;
  width: max-content;
  height: 56px;
  background: #F7FCFF;
  border: 1px solid #008EEF;
  box-sizing: border-box;
  border-radius: 8px;
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #008EEF;
  cursor: pointer;
}
.skills{
  font-family: DM Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  letter-spacing: 0.1em;
  color: #1E323F;
}
.scroll {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 90vh;
}
.scroll::-webkit-scrollbar {
  background: transparent !important;
  width: 5px;
  height: 8px;
}
.scroll::-webkit-scrollbar-thumb {
  background: #c4c4c4 !important;
  max-height: 20px !important;
  border-radius: 6px;
}


</style>